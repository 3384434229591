// Fonts
@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700&display=swap');

// Variables
@import 'variables';

//Functions
@import "functions";

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "select2";
@import "select2-bootstrap4";

//Fontawesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@import "color_definitions";
@import "widths";
@import "boxes";
@import "spans";
@import "sidebar";
@import "navbar";
@import "footer";
@import "general";

