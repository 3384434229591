// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Blinker', sans-serif;

$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #008080; //#4dc0b5;
$cyan: #6cb2eb;

/**dashborad colors**/
$deep-pink: #880E4F;
$deep-indigo: #1A237E;
$deep-teal: #004D40;
$deep-orange: #DD2C00;
$blue-gray: #607D8B;
$deep-red: #b71c1c;
$side-nav-bg-color: #7386D5;

$color-accent: #c7942b;
$primary-color: $teal;
$primary-color-dark: darken($primary-color, 30%);
$primary-color-light: lighten($primary-color, 30%);

$secondary-color: darken($color-accent, 30%);
$secondary-color-dark: darken($secondary-color, 20%);
$secondary-color-light: lighten($secondary-color, 20%);

$breadcrumb-divider: quote(">");

$theme-colors: (
    "primary": $primary-color,
)
