@import "variables";

//1. Primary definitions
.bg-theme-primary{
  background: $primary-color !important;
}

.text-theme-primary {
  color: $primary-color !important;
}

.bg-theme-primary-dark{
  background: $primary-color-dark !important;
}

.text-theme-primary-dark {
  color: $primary-color-dark !important;
}
.bg-theme-primary-light{
  background: $primary-color-light !important;
}

.text-theme-primary-light {
  color: $primary-color-light !important;
}

//2. Secondary definitions
.bg-theme-secondary{
  background: $secondary-color !important;
}

.text-theme-secondary {
  color: $secondary-color !important;
}

.bg-theme-secondary-dark{
  background: $secondary-color-dark !important;
}

.text-theme-secondary-dark {
  color: $secondary-color-dark !important;
}
.bg-theme-secondary-light{
  background: $secondary-color-light !important;
}

.text-theme-secondary-light {
  color: $secondary-color-light !important;
}

//3. Color Accent
.bg-theme-accent{
  background: $color-accent !important;
}
.text-theme-accent {
  color: $color-accent !important;
}

.text-dark-red {
  color: #8b0000 !important;
}

.text-orange {
  color:  #ffa500;
}






//General color definition
.bg-blue-gray{
  background: $blue-gray !important;
}
.bg-deep-pink{
  background: $deep-pink !important;
}

.bg-deep-indigo{
  background: $deep-indigo !important;
}

.bg-deep-teal{
  background: $deep-teal !important;
}

.bg-deep-orange{
  background: $deep-orange !important;
}

.bg-green{
  background: $green !important;
}

.bg-deep-red{
  background: $deep-red !important;
}
.font-deep-red{
  color: $deep-red !important;
}

.bg-purple{
  background: $purple !important;
}
.bg-cyan{
  background: $cyan !important;
}

.bg-teal{
  background: $teal !important;
}
