.box{
  min-height: 150px !important;
  min-width: 150px !important;
  border-radius: 0 !important;
}
.circular-box {
  min-height: 150px !important;
  min-width: 150px !important;
  border-radius: 50% !important;
}

/*.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}*/
.shadow-wider{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.shadow-narrow{
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
}
