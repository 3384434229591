@import "variables";

span.required, span.asteriskField{
  color: $red !important;
  font-weight: bold;
}
span.required::before{
  content: '*' !important;
}

span.help-block{
  font-size: 12px !important;
  color: #636363;
}
span.error-block {
  font-size: 12px !important;
  color: $red;
}