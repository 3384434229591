@import 'variables';

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

    .nav-link {
        font-weight: 500;
        color: #333;
    }

    .nav-link:hover,
    .nav-link.active {
        color: $primary-color;
    }
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}
