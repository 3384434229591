.width-5{
  width: 5% !important;
}
.width-10{
  width: 10% !important;
}
.width-20{
  width: 20% !important;
}
.width-25{
  width: 25% !important;
}
.width-30{
  width: 30% !important;
}
.width-50{
  width: 50% !important;
}
.width-45{
  width: 45% !important;
}
.width-48{
  width: 48% !important;
}
.width-49{
  width: 49% !important;
}
.width-40{
  width: 40% !important;
}
.width-70{
  width: 70% !important;
}
.width-100{
  width: 100% !important;
}

.height-70-vh{
  min-height: 70vh;
}
.height-75-vh{
  min-height: 75vh;
}
.height-80-vh{
  min-height: 80vh;
}
.height-90-vh{
  min-height: 90vh;
}
.height-100-vh{
  min-height: 100vh;
}

