@import "variables";
html, body {
  background-color: #fff;
  color: #636b6f;
  font-family: 'Blinker', sans-serif;
  font-weight: 300;
  height: 100vh;
  margin: 0;
  font-size: .9rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0 !important;
  margin-left: 0 !important;
  /* margin-right: -15px; */
  /* margin-left: -15px; */
}

#app{
  //min-height: 85vh !important;
}
main{
    min-height: 77.5vh !important;
}
.card-header{
  font-size: 1.2em;
}
.login-header{
  font-size: 1.5em;
}
.text-underline{
  text-decoration: underline !important;
}
.nowrap{
  white-space: nowrap !important;

}
a.home-link{
  text-decoration: none !important;
  .fa, .fas {
    font-size: 3em;
    color: $color-accent;
  }
}
a.home-link:hover{
  text-decoration: none!important;
}
//main page links
@media (max-width: 576px) {
  .home-links{
    text-align: center !important;
    a{
      margin-top: 10px;
    }
  }
}

img.img-logo{
  height: 150px !important;
}
.card-logo-image{
  max-width: 20em;
}
.show-color {

}
.text-sm {
  font-size: 9px !important;
}
.character-display {
  font-size: 20px;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  font-family: apple color emoji, segoe ui emoji, notocoloremoji, segoe ui symbol, android emoji, emojisymbols, emojione mozilla, sans-serif;
}

tr,td .valign-middle {
  vertical-align: middle;
}

.text-small {
  font-size: small !important;
}

.breadcrumb {
    a{
        color: $primary-color;
    }
    a:hover {
        color: $primary-color-dark;
    }
}
